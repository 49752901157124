import React from 'react';
import ProductPage from './pages/ProductPage/ProductPage';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { createBrowserHistory } from 'history';
import Home from './pages/HomePage/Home';

const history = createBrowserHistory();

function App() {
  return (
    <BrowserRouter>
      <Routes history={history}>
        <Route path="/" element={<Home/>}/>
        <Route path="/product/:productId" element={<ProductPage/>}/>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
