import React from 'react';
import styles from '../Home.module.css';

function ProductCard(props) {
  const { product } = props;

  return (
    <div style={{width: '250px', display: 'flex', borderRadius: '20px', border: '1px solid #DE0E0E', margin: '0 auto'}}>
        <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'space-between'}}>
            <div style={{padding: 20, paddingTop: 53, position: 'relative', display: 'block'}}>
                <img style={{position: 'absolute', height: '80px', left: '12px', top: '-6px'}} src='https://dev.cdn.hiveb.com.br/assets/amazon_logo.png'/>
                <div style={{display: 'flex', justifyContent: 'center', alignContent: 'center', width: 210, height: 210, background: `url(${product.image_url}) no-repeat center center`, backgroundSize: 'contain'}}/>
            </div>
            <h1 style={{fontFamily: "'Exo 2'", fontWeight: 600, padding: '0 20px', fontSize: '14px', textAlign: 'center', paddingTop: '1em', overflow: 'hidden', textOverflow: 'ellipsis', maxHeight: '60px'}}>{product.name}</h1>
            <h1 style={{marginTop: '10px', padding: '0 20px', fontFamily: 'Montserrat', fontSize: '22px', fontWeight: 800, textAlign: 'center', color: '#FA383E'}}>{product.price.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}</h1>
            <a href={product.url} target='_blank' className={styles.productButton} style={{backgroundColor: '#FA383E', borderRadius: '10px', margin: '0 20px', marginBottom: '20px'}}>
                <p style={{color: 'white', fontFamily: 'Montserrat', fontWeight: 'bold', margin: 0, padding: '10px 0', textAlign: 'center'}}>IR À LOJA</p>
            </a>
        </div>
    </div>
  );
}

export default ProductCard;
