export const apiUrl = "https://coral-app-qk999.ondigitalocean.app";
export const merchants = {
    'manda.promo': {
        merchant: 'mandapromo',
        logo: 'https://afiliados.hiveb.com.br/assets/mandapromo_logo_image.png'
    },
    'localhost:3000': {
        merchant: 'mandapromo',
        logo: 'https://afiliados.hiveb.com.br/assets/mandapromo_logo_image.png'
    }
}