import React, { useState, useEffect } from 'react';
import { apiUrl } from '../../config';
import ProductCard from './components/ProductCard';
import styles from './Home.module.css';
import { Spin } from 'antd';
import { LoadingOutlined, SearchOutlined  } from '@ant-design/icons';
import { Input, Button } from 'antd';

function Home() {
  const [loading, setLoading] = useState(true);
  const [products, setProducts] = useState(null);
  const [filterText, setFilterText] = useState(null);
  const [filteredProducts, setFilteredProduct] = useState(products);

  useEffect(() => {
    const fetchProducts = async () => {
      const response = await fetch(`${apiUrl}/products`);
  
      if (response.status !== 200) {
        setLoading(false);
        return;
      }
      
      const responseBody = await response.json();
      setProducts(responseBody);
      setFilteredProduct(responseBody);
      setLoading(false);
    }

    fetchProducts();
  }, []);

  return (
    <div className={styles.mainContainer}>
        <div className={styles.headerContainer}>
            <img className={styles.logoImage} src='https://dev.cdn.hiveb.com.br/assets/mandapromo_logo.png' alt="logo"/>
        </div>

        {loading && (
            <div className={styles.loadingContainer}>
                <Spin indicator={<LoadingOutlined style={{ fontSize: 64, color: '#FA383E' }} spin />}/>
            </div>
        )}

        {filteredProducts && (
            <div style={{display: 'flex', maxWidth: '900px', flexDirection: 'column', margin: '0 auto', padding: '0 1rem', marginBottom: '2rem', justifyContent: 'center'}}>
                <div style={{display: 'flex', marginTop: '40px'}}>
                  <h1 className={styles.title} style={{flex: 1, fontSize: '43px', fontFamily: 'Montserrat', fontWeight: '800', textTransform: 'uppercase'}}>encontre tudo o que você precisa </h1>
                  <div style={{height: '150px'}} className={styles.rotatingLogo}>
                    <img style={{height: '100%'}} src='https://dev.cdn.hiveb.com.br/assets/mandapromo_animation.gif'/>
                  </div>
                </div>
                <div className={styles.productList}>
                    {filteredProducts.map((product) => (
                      <ProductCard key={product.id} product={product}/>
                    ))}
                </div>
            </div>
        )}
    </div>
  );
}

export default Home;
