import { Typography, Button, Spin } from 'antd';
import React, { useState, useEffect } from 'react';
import styles from './ProductPage.module.css';
import { LoadingOutlined } from '@ant-design/icons';
import { apiUrl, merchants } from '../../config';
import { useParams } from "react-router-dom";

function ProductPage(props) {
  const [loading, setLoading] = useState(true);
  const [product, setProduct] = useState(null);
  const { productId } = useParams();

  useEffect(() => {
    const fetchProduct = async () => {
      const response = await fetch(`${apiUrl}/product/${productId}`);
  
      if (response.status !== 200) {
        setLoading(false);
        return;
      }
      
      const responseBody = await response.json();
      setProduct(responseBody);
      setLoading(false);
    }

    fetchProduct();
  }, [productId]);

  return (
    <div className={styles.productPageMainContainer}>
      <div className={styles.headerContainer}>
        <a href={`https://${window.location.host}`}>
          <img className={styles.logoImage} src='https://dev.cdn.hiveb.com.br/assets/mandapromo_logo.png' alt="logo"/>
        </a>
      </div>

      {loading && (
        <div className={styles.loadingContainer}>
          <Spin indicator={<LoadingOutlined style={{ fontSize: 64, color: '#FA383E' }} spin />}/>
        </div>
      )}

      {!loading && product &&(
        <div className={styles.productContainer}>
          <div className={styles.productSubContainer}>
            <div style={{padding: '20px', position: 'relative'}}>
              <img style={{position: 'absolute', height: '80px', left: '12px', top: '-40px'}} src='https://dev.cdn.hiveb.com.br/assets/amazon_logo.png'/>
              <div style={{display: 'flex', justifyContent: 'center', alignContent: 'center', minWidth: 270, minHeight: 270, background: `url(${product.image_url}) no-repeat center center`, backgroundSize: 'contain'}}/>
            </div>
            <div className={styles.productInternalContainer}>
              <div className={styles.productInternalLeftContainer}>
                <Typography.Title className={styles.productTitle} level={2}>{product.name}</Typography.Title>
                <div className={styles.priceContainer}>
                  <Typography.Paragraph className={styles.productPrice}>{product.price.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}</Typography.Paragraph>
                </div>
              </div>
              <div className={styles.productInternalRightContainer}>
                <a href={product.url} target='_blank'>
                  <Button type='primary' disabled={product.expired} className={styles.buttonProduct} style={{backgroundColor: product.expired ? 'rgba(0,0,0,.12)' : '#FA383E'}}>
                    {product.expired ? 'Oferta encerrada :(': 'Pegar Promo!'}
                  </Button>
                </a>
              </div>
            </div>
          </div>
        </div>
      )}

      {!loading && !product && (
        <div className={styles.errorContainer}>
          <Typography.Title level={2}>Erro ao carregar oferta, por favor recarregue a página</Typography.Title>
        </div>
      )}

    </div>
  );
}

export default ProductPage;
